import React from 'react';
import { getThemeClasses } from '@entrycall/ui-tailwind';

interface ButtonAtomProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}


const mode = 'dark';
const themeClasses = getThemeClasses(mode);
import classNames from 'classnames';

const newLocal = classNames(
  'w-full',
  'inline-flex',
  'justify-center',
  'whitespace-nowrap',
  'rounded-lg',
  themeClasses.backgroundColor,
  'hover:opacity-75',

  'px-3.5',
  'py-2.5',
  'text-sm',
  'font-medium',
  'text-white',
  'shadow-sm',
  'shadow-indigo-950/10',

  'focus:outline-none',
  'focus:ring',
  themeClasses.focusRingColor,
  'focus-visible:outline-none',
  'focus-visible:ring',
  'focus-visible:opacity-35',

  'transition-colors',
  'duration-150'
);

const ButtonAtom = React.forwardRef<HTMLButtonElement, ButtonAtomProps>(({ text, ...props }, ref) => (
  <button
    type="submit"
    ref={ref}
    className={newLocal}
    {...props}
  >
    {text}
  </button>
));

export default ButtonAtom;
