// lib/routes/auth/src/lib/backend/action/send_code/auth.action.server.ts
import { redirect, type ActionFunctionArgs } from '@remix-run/node';
import {
  axiosApiRequest,
  type VerificationPayload,
} from '@entrycall/lib-api-server';
import { handleError } from '../handleError.server';
import { createAxiosConfig } from '../createAxiosConfig.server';
import { validateResponse } from '../validateResponse.server';
import { pino } from 'pino';
import { URL } from 'url';

const logger = pino({ name: 'authAction' });

const EMAIL_PARAM = 'email';
const PHONE_PARAM = 'phone';
const AUTH_OTP_ENDPOINT = '/auth/otp';

enum HttpMethod {
  POST = 'post',
  GET = 'get',
}

type LoginMethod = 'email' | 'phone';

interface LoginData {
  identifier: string;
  method: LoginMethod;
}

interface ApiConfig {
  baseURL: string;
  method: HttpMethod;
  endpoint: string;
  urlPath: string;
}

/**
 * Main authentication action function.
 * Handles form submission from the authentication page, processes login data, and initiates verification.
 *
 * @param {ActionFunctionArgs} params - The parameters provided by Remix, including the request object.
 * @returns {Promise<Response>} The response to the client, including redirection or error handling.
 */
export async function authAction({
  request,
}: ActionFunctionArgs): Promise<Response> {
  const apiConfig: ApiConfig = {
    method: HttpMethod.POST,
    baseURL: process.env.API_VERIFY_URL || '',
    urlPath: '/auth/verification',
    endpoint: AUTH_OTP_ENDPOINT,
  };

  const isDevelopment = process.env.NODE_ENV === 'development';

  logger.info('Auth action initiated', apiConfig);
  if (isDevelopment) console.log('Auth action initiated', apiConfig);

  const url = new URL(request.url);
  const resend = url.searchParams.get('resend') === 'true';

  try {
    if (resend) {
      return await resendVerificationCode(url, apiConfig);
    } else {
      const formData = await request.formData();
      const loginData = validateLoginData(formData);
      return await handleLoginByType(loginData, apiConfig);
    }
  } catch (error: unknown) {
    logger.error('Error in auth action:', error);
    if (isDevelopment) console.error('Error in auth action:', error);
    const { error: errorMessage, details } = handleError(error);
    return new Response(JSON.stringify({ error: errorMessage, details }), {
      status: 400,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

/**
 * Validates the login data from the form submission.
 *
 * @param {FormData} formData - The form data submitted by the user.
 * @returns {LoginData} Validated login data.
 * @throws {Error} If invalid login data is provided.
 */
function validateLoginData(formData: FormData): LoginData {
  const email = formData.get(EMAIL_PARAM);
  const phone = formData.get(PHONE_PARAM);

  if (email && typeof email === 'string') {
    return { identifier: email, method: 'email' };
  } else if (phone && typeof phone === 'string') {
    return { identifier: formatPhoneNumber(phone), method: 'phone' };
  }

  throw new Error('Invalid login data provided');
}

/**
 * Handles the login process based on the login type.
 *
 * @param {LoginData} loginData - The validated login data.
 * @param {ApiConfig} apiConfig - The API configuration.
 * @returns {Promise<Response>} The redirect response after processing the login.
 */
async function handleLoginByType(
  loginData: LoginData,
  apiConfig: ApiConfig,
): Promise<Response> {
  const { identifier, method: loginMethod } = loginData;
  const { baseURL, method, endpoint, urlPath } = apiConfig;
  const encodedIdentifier = encodeURIComponent(identifier);
  const redirectUrl = `${urlPath}?${loginMethod}=${encodedIdentifier}`;

  logger.info('Handling login', { loginMethod, identifier, redirectUrl });
  if (process.env.NODE_ENV === 'development') {
    console.log('Handling login', { loginMethod, identifier, redirectUrl });
  }

  const payload: VerificationPayload = { loginId: identifier, verifyCode: '' };
  const fullUrl = new URL(endpoint, baseURL).toString();
  const axiosConfig = createAxiosConfig(fullUrl, payload);

  logger.info('Sending API request', { endpoint, method, payload });
  if (process.env.NODE_ENV === 'development') {
    console.log('Sending API request', { endpoint, method, payload });
  }

  const response = await axiosApiRequest(axiosConfig, '', method, payload);
  validateResponse(response);

  return redirect(redirectUrl);
}

/**
 * Formats a phone number to remove spaces, dashes, and other characters.
 *
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
function formatPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/[\s\-()]/g, '');
}

export async function resendVerificationCode(
  url: URL,
  apiConfig: ApiConfig,
): Promise<Response> {
  try {
    const email = url.searchParams.get('email');
    const method = url.searchParams.get('method');
    const data = url.searchParams.get('data');

    if (!method || !data) {
      throw new Error(
        'Invalid input: both method and data must be provided in the URL',
      );
    }

    const loginData: LoginData = {
      identifier: data,
      method: method as LoginMethod,
    };

    // Use the loginData to resend the verification code
    return await handleLoginByType(loginData, apiConfig);
  } catch (error) {
    logger.error('Error in newLoginRequest:', error);
    if (process.env.NODE_ENV === 'development') {
      console.error('Error in newLoginRequest:', error);
    }
    const { error: errorMessage, details } = handleError(error);
    return new Response(JSON.stringify({ error: errorMessage, details }), {
      status: 400,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
