import React from 'react';
import SectionWrapper from '../main-wrapper.ui';
import OtpForm from '../../components/templates/verify/verify-form';

const VerifyPage: React.FC = () => {
  return (
    <SectionWrapper>
      <OtpForm />
    </SectionWrapper>
  );
};

export default VerifyPage;
