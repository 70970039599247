// lib/routes/auth/src/lib/frontend/components/molecules/auth/email/email-Input_fields.tsx

import React from 'react';
import AuthInputFields from '../input_fields'; // Importing the base input fields component
import { EnvelopeIcon } from '@heroicons/react/20/solid'; // Importing the icon for the email input

// Props interface for the EmailInputField component
interface EmailInputFieldProps {
  /**
   * The error message to display, if any. It will be shown below the input field
   * if the email input is invalid. If null, no error message will be displayed.
   */
  error: string | null;

  /**
   * A function to handle the change event of the input field. This is typically used
   * to update state or clear error messages when the user interacts with the input.
   */
  onChange: () => void;
}

/**
 * EmailInputField Component
 *
 * This component renders an input field specifically for email input, including
 * an email icon and handling error display. It uses the AuthInputFields component
 * for consistent styling and behavior with other input fields.
 *
 * @param {EmailInputFieldProps} props - The properties for this component.
 * @returns {JSX.Element} The rendered email input field component.
 */
const EmailInputField: React.FC<EmailInputFieldProps> = ({ error, onChange }) => {
  return (
    <AuthInputFields
      id="email" // Unique identifier for the input field
      name="email" // Name attribute for the input, used in form submissions
      type="email" // Specifies that the input field is for email addresses
      placeholder="you@example.com" // Placeholder text shown inside the input field
      icon={<EnvelopeIcon className="h-5 w-5 text-gray-400" />} // Icon displayed in the input field
      errorText={error} // The error message text to display, if any
      isInvalid={!!error} // Boolean to indicate if the input is in an invalid state
      onChange={onChange} // Function to handle input changes
    />
  );
};

export default EmailInputField;
