// lib/routes/auth/src/lib/frontend/components/organisms/verify/verify-form.tsx

import { useEffect, useRef, useState } from 'react';
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from '@remix-run/react';
import HeaderMolecule from '../../molecules/verify/verify-header';
import OtpInputs from '../../molecules/verify/otp-inputs';
import ButtonAtom from '../../atoms/button.atom.ui';
import ErrorMessage from '../../atoms/auth/error_message-auth.atom.ui';
import { isMobile } from '@entrycall/utility-screen-size-checker';
import { type ActionData } from '../../../types';

const OtpFormOrganism = () => {
  const { method, data } = useLoaderData<{
    method: string | null;
    data: string | null;
  }>();

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const submit = useSubmit();
  const navigation = useNavigation();
  const actionData = useActionData<ActionData>();
  const [resendCount, setResendCount] = useState(0);
  const [errorText, setError] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [resendMessage, setResendMessage] = useState('');
  const [resetTimer, setResetTimer] = useState(false);

  console.log('OtpFormOrganism - data', data);

  useEffect(() => {
    if (actionData && actionData.error) {
      setError(
        actionData.details?.errorMessage ||
          actionData.error ||
          'An unexpected error occurred',
      );
    } else {
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    // Update the isMobileView state on component mount to ensure consistent client-side rendering
    setIsMobileView(isMobile());
  }, []);

  useEffect(() => {
    if (resendCount > 0) {
      setResendMessage('Code resent successfully');
      const timer = setTimeout(() => setResendMessage(''), 3000); // Clear message after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [resendCount]);

  /*
   * Define the Tailwind CSS classes for the button wrapper based on screen size.
   * The `mx-auto mt-4` classes are always applied to center the button and provide top margin.
   * If the screen is not considered mobile (i.e., wider than 640px), the `max-w-[260px]` class is also added
   * to limit the maximum width of the button wrapper for better alignment on larger screens.
   */
  const buttonWrapperTailwindClasses = `mx-auto mt-4 ${!isMobileView ? 'max-w-[260px]' : ''}`;
  const handleResendCode = () => {
    submit(null, {
      method: 'post',
      action: '/auth/login?resend=true&method=' + method + '&data=' + data,
    });
    setResendCount((prevCount) => prevCount + 1);
    setTimeLeft(180); // Reset the timer to 3 minutes
    setResetTimer((prev) => !prev); // Toggle this state to trigger useEffect
  };

  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds

  useEffect(() => {
    setTimeLeft(180); // Reset to 3 minutes when resetTimer changes
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [resetTimer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const description =
    method === 'phone'
      ? `Enter the 6-digit verification code sent to your phone number ${data}.`
      : method === 'email'
        ? `Enter the 6-digit verification code sent to your email address ${data}.`
        : 'Enter the 6-digit verification code that was sent to your phone number or email address.';

  return (
    <article>
      {/* <HeaderMolecule description={description}/> */}
      <HeaderMolecule
        description={`${description} ${timeLeft ? `Code expires in: ${formatTime(timeLeft)}` : 'Code expired'}`}
      />
      <Form method="post" id="otp-form" className="max-w-md mx-auto">
        <OtpInputs />
        <ErrorMessage errorText={errorText} />
        <div className={buttonWrapperTailwindClasses}>
          <ButtonAtom
            ref={submitButtonRef}
            text={
              navigation.state === 'submitting'
                ? 'Loading...'
                : 'Verify Account'
            }
          />
        </div>
      </Form>
      <div className="text-sm text-slate-500 mt-4">
        Didn't receive code?
        <button
          onClick={handleResendCode}
          className={`text-blue-600 hover:text-blue-800 ml-1 ${timeLeft > 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={timeLeft > 0}
        >
          Resend {timeLeft > 0 ? `(${formatTime(timeLeft)})` : ''}
        </button>
        {resendMessage && (
          <p className="text-green-600 mt-2">{resendMessage}</p>
        )}
      </div>
    </article>
  );
};

export default OtpFormOrganism;
