import React from 'react';
import classNames from 'classnames';
import background from '../styles/background.png'; // Adjust path as needed

interface SectionWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes = classNames(
    className,
    //* All screen sizes
    'min-h-screen',
    'flex',
    'justify-center',
    'items-center',
    //* Min-width 640px
    'tablet:min-h-screen',
    'bg-cover',
    'bg-center',
    'bg-no-repeat',
  );

  return (
    <main
      {...rest}
      className={classes}
      style={{ backgroundImage: `url(${background})` }}
    >
      {children}
    </main>
  );
};

export default SectionWrapper;
