import React, { useState } from 'react';
import AuthLabel from '../../../atoms/auth/label-auth.atom.ui';
import AuthErrorIcon from '../../../atoms/auth/error_icon-auth.atom.ui';
import ErrorMessage from '../../../atoms/auth/error_message-auth.atom.ui';

// Props interface for the PhoneInputField component
interface PhoneInputFieldProps {
  error: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * PhoneInputField Component
 *
 * This component renders an input field specifically for phone number input, including
 * a phone icon and handling error display.
 *
 * @param {PhoneInputFieldProps} props - The properties for this component.
 * @returns {JSX.Element} The rendered phone input field component.
 */
const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  error,
  onChange,
}) => {
  const [value, setValue] = useState('');
  const isInvalid = !!error;
  const erroStyle = isInvalid
    ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500'
    : 'text-gray-900';

  // Use the formatPhoneNumber utility to format the error message if it contains a phone number
  const errorMessage = error ? formatPhoneNumber(error) : null;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Remove all non-digit characters
    inputValue = inputValue.replace(/\D/g, '');

    // Format with parentheses and dashes (e.g., (123) 456-7890)
    if (inputValue.length > 3 && inputValue.length <= 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3)}`;
    } else if (inputValue.length > 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }

    setValue(inputValue);
    onChange(e); // Call the passed onChange handler
  };

  return (
    <div className="mt-4">
      <AuthLabel htmlFor={'phone'}></AuthLabel>
      <div className="relative mt-2 rounded-md border">
        <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
          <svg
            className={`w-4 h-4 ${isInvalid ? `text-red-900` : `text-gray-500`}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 19 18"
          >
            <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
          </svg>

          {/* //* previous icon element */}
          {/* <IconWrapper>
            {<SVGIcon svgPath={phoneSVGPath} className="size-6" />}
          </IconWrapper> */}
        </div>
        <input
          id="phone"
          name="phone"
          type="text"
          placeholder="(123) 456-7890"
          aria-describedby="helper-text-explanation"
          className={`${erroStyle} block w-full rounded-md border-0 pl-14 py-1.5 pr-10`}
          maxLength={14}
          onChange={handleInputChange}
          value={value}
          required
        />
        {isInvalid && <AuthErrorIcon />}
      </div>
      {isInvalid && <ErrorMessage id="phone" errorText={errorMessage} />}
    </div>
  );
};

export default PhoneInputField;

/**
 * Formats a phone number within a message string to the format +1 (123) 456-7890.
 *
 * @param message - The input message containing the phone number. The phone number is expected to be in the format +11234567890.
 * @returns The message with the phone number formatted as +1 (123) 456-7890.
 */
const formatPhoneNumber = (message: string): string => {
  const regex = /(\+\d)(\d{3})(\d{3})(\d{4})/;
  const formattedMessage = message.replace(
    regex,
    (_, p1, p2, p3, p4) => `${p1} (${p2}) ${p3}-${p4}`,
  );
  // console.log('Original message:', message);
  // console.log('Formatted message:', formattedMessage);
  return formattedMessage;
};
