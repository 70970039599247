// lib/routes/auth/src/lib/backend/action/verify_code/verify.action.server.ts
import type { ActionFunctionArgs } from '@remix-run/node';
import {
  axiosApiRequest,
  type VerificationPayload,
} from '@entrycall/lib-api-server';
import { handleError } from '../handleError.server';
import { createAxiosConfig } from '../createAxiosConfig.server';
import { validateResponse } from '../validateResponse.server';
import { pino } from 'pino';
import { URL } from 'url';

const logger = pino({ name: 'verifyAction' });

const EMAIL_PARAM = 'email';
const PHONE_PARAM = 'phone';
const VERIFICATION_CODE_PREFIX = 'verification-code-';
const AUTH_OTP_VERIFY_ENDPOINT = '/auth/otp/verify';

enum HttpMethod {
  POST = 'post',
  GET = 'get',
}

export interface IVerifyAction {
  token: string | null;
  refreshToken: string | null;
  error: string | null;
  details: any | null;
}

type LoginId = string | null;

/**
 * Action function for verifying OTP (One-Time Password).
 * This function handles the OTP verification process by:
 * 1. Extracting the verification code from the form data
 * 2. Retrieving the login ID (email or phone) from the URL parameters
 * 3. Creating a payload with the login ID and verification code
 * 4. Sending a POST request to the OTP verification endpoint
 * 5. Processing the response and returning the result
 *
 * @param {ActionFunctionArgs} args - The arguments passed to the action function
 * @returns {Promise<IVerifyAction>} A promise that resolves to an object containing the verification result
 */
export async function verifyAction({
  request,
}: ActionFunctionArgs): Promise<IVerifyAction> {
  const baseURL = process.env.API_VERIFY_URL ?? 'https://api.default.com';

  // Environment check for development
  const isDevelopment = process.env.NODE_ENV === 'development';

  try {
    if (isDevelopment) console.log('Starting verifyAction');

    const body = await request.formData();
    validateFormData(body);

    const verificationCode = getVerificationCode(body);
    if (typeof verificationCode !== 'string' || verificationCode.length !== 6) {
      throw new Error('Invalid verification code format');
    }

    if (isDevelopment) console.log('Verification code:', verificationCode);

    const url = new URL(request.url);
    const savedEmail = url.searchParams.get(EMAIL_PARAM);
    const savedPhone = url.searchParams.get(PHONE_PARAM);

    const loginId = savedEmail || savedPhone;
    if (!loginId) {
      throw new Error(
        'Neither Email nor Phone found in URL. Please try again.',
      );
    }

    if (isDevelopment) console.log('Login ID:', loginId);

    const payload: VerificationPayload = {
      loginId,
      verifyCode: verificationCode,
    };
    logger.info('Verification payload:', payload);
    if (isDevelopment) console.log('Verification payload:', payload);

    const fullUrl = constructApiUrl(baseURL, AUTH_OTP_VERIFY_ENDPOINT);
    if (isDevelopment) console.log('Full API URL:', fullUrl);

    const axiosConfig = createAxiosConfig(fullUrl, payload);
    const response = await axiosApiRequest(
      axiosConfig,
      '', // Empty string as endpoint is now part of fullUrl
      HttpMethod.POST,
      payload as VerificationPayload,
    );
    validateResponse(response);

    if (isDevelopment) console.log('API Response:', response.data);

    const token: string | null = response.data?.token?.jwt ?? null;
    const refreshToken: string | null =
      response.data?.refreshToken?.jwt ?? null;

    if (isDevelopment)
      console.log('Token:', token ? 'Received' : 'Not received');
    if (isDevelopment)
      console.log('Refresh Token:', refreshToken ? 'Received' : 'Not received');

    return { token, refreshToken, error: null, details: null };
  } catch (error: any) {
    logger.error('An error occurred:', error);
    if (isDevelopment) console.error('An error occurred:', error);
    const { error: errorMessage, details } = handleError(error);
    return { token: null, refreshToken: null, error: errorMessage, details };
  }
}

/**
 * Validates the form data to ensure it contains the necessary verification code parts.
 * This function checks if the form data includes at least the first part of the verification code.
 *
 * @param {FormData} body - The form data to validate
 * @throws {Error} If the verification code data is missing
 */
function validateFormData(body: FormData): void {
  if (!body.has(VERIFICATION_CODE_PREFIX + '0')) {
    throw new Error('Missing verification code data');
  }
}

/**
 * Extracts and combines the verification code parts from the form data.
 * This function retrieves up to 6 parts of the verification code from the form data
 * and concatenates them into a single string.
 *
 * @param {FormData} body - The form data containing the verification code parts
 * @returns {string} The combined verification code
 */
function getVerificationCode(body: FormData): string {
  return Array.from(
    { length: 6 },
    (_, i) => body.get(`${VERIFICATION_CODE_PREFIX}${i}`)?.toString() ?? '',
  ).join('');
}

/**
 * Creates the payload for the OTP verification request based on the login ID type.
 * This function determines whether the login ID is an email or phone number
 * and constructs the appropriate payload object.
 *
 * @param {LoginId} loginId - The login ID (email or phone number)
 * @param {string} verificationCode - The verification code
 * @returns {object} The payload object for the verification request
 * @throws {Error} If the login ID format is invalid or missing
 */
function createPayload(loginId: LoginId, verificationCode: string): object {
  if (!loginId) {
    throw new Error('Login ID is missing');
  }

  const isEmail = loginId.includes('@');
  return {
    loginId,
    verifyCode: verificationCode,
    ...(isEmail ? { email: loginId } : { phone: loginId }),
  };
}

/**
 * Constructs the full API URL by combining the base URL and the endpoint.
 * This function ensures that the resulting URL is properly formatted.
 *
 * @param {string} baseUrl - The base URL of the API
 * @param {string} endpoint - The specific endpoint to be appended to the base URL
 * @returns {string} The full API URL
 */
function constructApiUrl(baseUrl: string, endpoint: string): string {
  return new URL(endpoint, baseUrl).toString();
}
