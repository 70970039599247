import React from 'react';
import classNames from 'classnames';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  isInvalid: boolean;
}

const AuthInput: React.FC<InputProps> = ({
  id,
  name,
  type,
  placeholder,
  isInvalid,
  onChange,
  ...rest
}) => (
  <input
    id={id}
    name={name}
    type={type}
    placeholder={placeholder}
    aria-describedby={`${id}-error`}
    className={classNames(
      'block w-full rounded-md border-0 pl-14 py-1.5 pr-10',
      isInvalid
        ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500'
        : 'text-gray-900',
    )}
    onChange={onChange} // Add this line
    {...rest} // Spread other input attributes
  />
);

export default AuthInput;
