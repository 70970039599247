// lib/routes/auth/src/lib/frontend/components/organisms/auth/auth-form.tsx
import { useState, useEffect } from 'react';
import { Form, useActionData, useNavigation } from '@remix-run/react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import HeaderMolecule from '../../molecules/auth/auth-header';
import ButtonAtom from '../../atoms/button.atom.ui';
import EmailInputField from '../../molecules/auth/email/email-Input_fields';
// import PhoneInputField from '../../molecules/auth/phone/phone-Input_fields';
import { isMobile } from '@entrycall/utility-screen-size-checker';
import React from 'react';

// import PhoneInputField from '../../molecules/auth/phone/phone-Input_fields';
import PhoneInputField from '../../molecules/auth/phone/phone-Input';
import { type ActionData } from '../../../types';

const AuthFormOrganism: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const data = useActionData<ActionData>();
  const navigation = useNavigation();
  const [error, setError] = useState<string | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  console.log(111111, 'article ->  data', data);

  useEffect(() => {
    if (data && data.error) {
      setError(data.details?.errorMessage || data.error || 'An unexpected error occurred');
    } else {
      setError(null);
    }
  }, [data]);

  useEffect(() => {
    // Update the isMobileView state on component mount to ensure consistent client-side rendering
    setIsMobileView(isMobile());
  }, []);

  const handleChange = () => {
    setError(null);
  };

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    setError(null); // Clear error when switching tabs
  };

  /*
   * Consolidated Tailwind CSS classes for the Tab component
   */
  const tabTailwindClasses =
    'w-1/2 rounded-full border border-transparent px-3 py-1.5 text-sm font-medium text-gray-500 hover:text-gray-900 data-[selected]:bg-white data-[selected]:text-gray-900';

  /*
   * Define the Tailwind CSS classes for the button wrapper based on screen size.
   * The `mx-auto mt-4` classes are always applied to center the button and provide top margin.
   * If the screen is not considered mobile (i.e., wider than 640px), the `max-w-[260px]` class is also added
   * to limit the maximum width of the button wrapper for better alignment on larger screens.
   */
  const buttonWrapperTailwindClasses = `mx-auto mt-4 ${!isMobileView ? 'max-w-[260px]' : ''}`;
  // console.log('isMobileView', isMobileView);

  /*
   * Determine the button text based on the current navigation state.
   * If the `navigation.state` is 'submitting', indicating that a form submission or other asynchronous action is in progress,
   * the button text is set to 'Loading...' to inform the user that the action is being processed.
   * Otherwise, the button text defaults to 'Login', indicating the default action the button performs.
   */
  const buttonText = navigation.state === 'submitting' ? 'Loading...' : 'Login';



  return (
    <article className='bg-white'>
      <HeaderMolecule />
      <Form method="post" className="max-w-md mx-auto">
        <TabGroup selectedIndex={selectedIndex} onChange={handleTabChange}>
          <TabList className="group flex items-center bg-gray-100 p-2 rounded-3xl">
            <Tab className={tabTailwindClasses}>Email</Tab>
            <Tab className={tabTailwindClasses}>Phone</Tab>
          </TabList>
          <TabPanels className="mt-2">
            <TabPanel className="p-0.5">
              <EmailInputField
                error={selectedIndex === 0 ? error : null}
                onChange={handleChange}
              />
            </TabPanel>
            <TabPanel className="p-0.5">
              <PhoneInputField
                error={selectedIndex === 0 ? error : null}
                onChange={handleChange}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div className={buttonWrapperTailwindClasses}>
          <ButtonAtom text={buttonText} />
        </div>
      </Form>
    </article>
  );
};

export default AuthFormOrganism;
